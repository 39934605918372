<template>
    <div>
        <MobileWineList
        :wine-list="viewWineList"
        :next-page="nextPage">
        </MobileWineList>
    </div>
</template>

<script>
    import MobileWineList from "../components/list/MobileWineList";
    export default {
        name: "MobileWineListSample",
        components: {MobileWineList},
        data() {
            return {
                viewWineList:[],
                wineList: [
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                        key:'1',
                        price: {
                            coin:1270,
                            won:'5,260,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                    },
                    {
                        status:2,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019 ',
                        key:'0x09x08e09x456x154fsd',
                        price: {
                            coin:1023,
                            won:'3,450,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png"
                    },
                    {
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2018 ',
                        key:'3',
                        price: {
                            coin:621,
                            won:'3,180,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/694e43dfe6197.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2019 ',
                        key:'4',
                        price: {
                            coin:1270,
                            won:'2,730,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2018 ',
                        key:'5',
                        price: {
                            coin:386,
                            won:'2,400,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/49d971f480586.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Laurent Roumier 4종 ',
                        key:'6',
                        price: {
                            coin:273,
                            won:'980,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/ed8551e9e72fb.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Bollinger La Grande Année 2012 ',
                        key:'7',
                        price: {
                            coin:105,
                            won:'470,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/d0c1e564d8f76.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC La Tache Grand Cru 2017 ',
                        key:'8',
                        price: {
                            coin:3672,
                            won:'27,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png"
                    },
                    {
                        status:3,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC Echezeaux Grand Cru 2018 ',
                        key:'9',
                        price: {
                            coin:1270,
                            won:'15,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Louis Roederer Cristal 2009 1.5 Magnum ',
                        key:'10',
                        price: {
                            coin:230,
                            won:'1,280,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220602/b65093f040236.jpg"
                    },
                ],
                secondWineList: [
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Louis Roederer Cristal 2009 1.5 Magnum ',
                        key:11,
                        price: {
                            coin:230,
                            won:'1,280,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220602/b65093f040236.jpg"
                    },
                    {
                        status:2,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2019 ',
                        key:12,
                        price: {
                            coin:1023,
                            won:'3,450,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/beab0e9d5b5df.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2019 ',
                        key:13,
                        price: {
                            coin:1270,
                            won:'2,730,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220509/e4ce616d1a5be.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Bonnes Mares Grand Cru 2018 ',
                        key:14,
                        price: {
                            coin:386,
                            won:'2,400,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220527/49d971f480586.png"
                    },
                    {
                        brand:'Cote de Nuits',
                        name:'[NFT] Domaine Comte Georges de Vogue Musigny Vieilles Vignes Grand Cru 2018 ',
                        key:15,
                        price: {
                            coin:621,
                            won:'3,180,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/694e43dfe6197.png"
                    },
                    {
                        status:3,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC Echezeaux Grand Cru 2018 ',
                        key:16,
                        price: {
                            coin:1270,
                            won:'15,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/b49d735daabe1.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                        key:17,
                        price: {
                            coin:1270,
                            won:'5,260,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] Bollinger La Grande Année 2012 ',
                        key:18,
                        price: {
                            coin:105,
                            won:'470,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220519/d0c1e564d8f76.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC La Tache Grand Cru 2017 ',
                        key:19,
                        price: {
                            coin:3672,
                            won:'27,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png"
                    },
                    {
                        status:1,
                        brand:'Cote de Nuits',
                        name:'[NFT] DRC La Tache Grand Cru 2017 ',
                        key:20,
                        price: {
                            coin:3672,
                            won:'27,000,000'
                        },
                        image: "https://cdn.imweb.me/thumbnail/20220523/6dc24ad72b88d.png"
                    },
                ],
                pageInfo: {
                    current_page:1,
                    total_page:2
                }
            }
        },
        created() {
            this.viewWineList = this.wineList
        },
        methods: {
            nextPage($state) {
                // https://peachscript.github.io/vue-infinite-loading/guide/start-with-hn.html
                // 통신 연동 부분
                if(this.pageInfo.current_page < this.pageInfo.total_page) {
                    this.viewWineList.push(...this.secondWineList)
                    this.pageInfo.current_page ++
                    $state.loaded(); // 다음 페이지가 있을 경우
                } else {
                    alert('마지막 페이지 입니다.')
                    $state.complete(); // 다음 페이지가 없을 경우
                }
            }
        }
    }
</script>

<style scoped>

</style>
